import React, { Component } from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import styled from 'styled-components';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import '../components/common/slick-theme.css';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { JSONLD, GenericCollection, Review } from 'react-structured-data';
import FeaturedSection from '../components/FeaturedSection';
import leftArrow from '../images/carousel-arrow-left.svg';
import Layout from '../components/layout';
import rightArrow from '../images/carousel-arrow-right.svg';
import SEO from '../components/seo';
import {
  COLOR_WHITE,
  COLOR_GRAY,
  OVERLAY_GRAY,
  MAX_WIDTH_SITE,
  COLOR_DARK_GRAY,
  screen,
  COLOR_GREEN,
  MAIN_HEADING_FONT,
  COLOR_TEXT_GRAY,
} from '../components/common/variables';
import { removeHTMLTags } from '../components/common/functions';

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
`;

const CarouselWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  display: none;
  z-index: 1;
  .slick-arrow {
    display: none !important;
  }
  @media ${screen.large} {
    display: block;
  }

  .banner-slider {
    position: relative;
    margin: 0;

    &__image {
      position: relative;
    }

    &__content-left,
    &__content-right {
      font-family: Hero, 'Source Sans Pro', Arial, Helvetica, sans-serif;
      position: absolute;
      top: 50%;
      right: auto;
      transform: translateY(-50%);
      max-width: 500px;
      padding: 20px;
      margin: 0 auto;
      @media ${screen.large} {
        left: 55px;
      }
      @media ${screen.largeXL} {
        left: 135px;
      }
      @media ${screen.large} {
        .desc {
          display: none;
        }
      }

      @media ${screen.largeXL} {
        .desc {
          display: unset;
        }
      }

      h1 {
        font-size: 1.4rem;
        margin: 0 0 10px 0;
        font-weight: 400;

        ${MAIN_HEADING_FONT};
        @media ${screen.large} {
          font-size: 1.35rem;
          max-width: 340px;
        }
        @media ${screen.largeXL} {
          font-size: 2rem;
          max-width: 1200px;
        }
        @media ${screen.largeXXL} {
          font-size: 2.4rem;
        }
        span {
          margin: 0;
          font-weight: 400;
        }
      }
      .desc {
        margin-top: 20px;
        line-height: 1.6rem;
        font-size: 1.1rem;
        @media ${screen.largeXXL} {
          font-size: 1.3rem;
        }
        font-weight: 400;
        span {
          margin-top: 20px;
          line-height: 1.4rem !important;
          font-size: 1.3rem;
        }
      }
      p {
        font-size: 1.5rem;
        margin: 0;
        font-weight: 500;
        ${MAIN_HEADING_FONT}
      }
      a {
        background: ${COLOR_GRAY};
        display: inline-block;
        margin-top: 20px;
        transition: all 0.28s ease-out;
        padding: 0.5rem 1.85rem 0.3rem 1.85rem;
        font-size: 1rem;
        border-radius: 5px;
        line-height: 1.15;
        font-weight: 400;
        display: table;
        border: 2px ${COLOR_GRAY} solid;
        @media ${screen.largeXL} {
          font-size: 1.1rem;
          padding: 0.6rem 2rem 0.4rem 2rem;
        }
        &:hover {
          background-color: ${COLOR_GREEN};
          border: 2px ${COLOR_GREEN} solid;
        }
      }
    }

    &__content-right {
      left: auto;
      @media ${screen.large} {
        right: 15px;
      }
      @media ${screen.largeXL} {
        right: 85px;
      }
    }
  }
`;

const ScenariosWrapper = styled.div`
  margin: 30px 0;
  @media ${screen.medium} {
    margin: 60px 0;
  }
  .slick-arrow {
    display: none !important;
  }
  @media ${screen.largeXL} {
    margin: 60px 0;
  }

  .heading {
    color: ${COLOR_DARK_GRAY};
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    padding: 0 35px;
    color: ${COLOR_GRAY};
    @media ${screen.medium} {
      margin: 0 0 10px 0;
      font-size: 1.8rem;
    }
    @media ${screen.largeXL} {
      font-size: 2.4rem;
      padding: 0;
    }
  }

  .subheading {
    color: ${COLOR_TEXT_GRAY};
    max-width: 700px;
    margin: 0 auto;
    font-size: 1rem;
    margin: 15px auto 0 auto;
    padding: 0 35px;
    text-align: center;
    @media ${screen.medium} {
      margin: 30px auto 0 auto;
      font-size: 1.1rem;
    }
    @media ${screen.largeXL} {
      padding: 0;
    }
  }

  .scenarios-list {
    display: flex;
    flex-wrap: wrap;
    margin: 60px 0;
    justify-content: center;
    padding: 0 20px;
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .scenarios {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    @media ${screen.medium} {
      width: 47%;
      margin: 15px 5px;
      margin-bottom: 0px;
    }

    @media (min-width: 1280px) {
      margin: 0;
      width: 25%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__image {
      display: block;
    }

    &__text-container {
      background: rgba(108, 173, 63, 0.93);
      border-radius: 9px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.2s ease-out;
      width: 85%;
      height: 85%;
      display: none;
      cursor: pointer;
      @media (min-width: 1280px) {
        display: flex;
        width: 90%;
        height: 90%;
      }

      &__subcontainer {
        margin: auto;
        align-self: center;
      }
    }

    &__title {
      color: ${COLOR_WHITE};
      margin: 0 auto 20px auto;
      padding-bottom: 12px;
      border-bottom: 1px ridge ${COLOR_WHITE};
      width: 70%;
      font-size: 1.4rem;
      font-weight: 300;
      @media (min-width: 1280px) {
        font-size: 1.4rem;
        padding-bottom: 5px;
        margin: 0 auto 10px auto;
      }
    }

    &__content {
      color: ${COLOR_WHITE};
      margin: 0 auto;
      font-size: 1rem;
      font-weight: 300;
      @media (min-width: 1280px) {
        width: 90%;
      }
    }

    &__link {
      @media ${screen.medium} {
        font-size: 0.9rem;
      }
      @media (min-width: 1280px) {
        display: block;
        font-size: 1rem;
        margin-top: 20px;
      }
      svg {
        vertical-align: middle;
        margin-left: 4px;
      }
    }
    &__mobile-navigate-link {
      width: 100%;
      background: ${COLOR_GREEN};
      position: absolute;
      z-index: 7;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px 0;
      @media (min-width: 1280px) {
        display: none;
      }

      h2 {
        color: ${COLOR_WHITE};
        padding-left: 35px;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: -2px;
      }

      svg {
        padding-right: 35px;
        font-size: 1.25rem;
        align-self: center;
      }
    }

    &--services {
      @media ${screen.medium} {
        display: none;
      }
    }
  }
`;

const TestimonialWrapper = styled.div`
  display: none;
  @media ${screen.largeXL} {
    display: block;
  }

  .slick-arrow {
    display: none !important;
  }

  .heading {
    color: ${COLOR_GRAY};
    text-align: center;
    font-size: 1.8rem;
  }

  .testimonial-slider {
    position: relative;
    height: 230px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 30px 0;

    &__image {
      align-self: flex-start;

      img {
        display: block;
        width: 120px;
      }
    }

    &__texts-container {
      max-width: 700px;
      margin-left: 45px;
    }
    .slick-arrow {
      display: none;
    }

    &__content {
      p {
        font-size: 1.4rem;
        font-weight: 100;
        line-height: 1.9rem;
        color: ${COLOR_TEXT_GRAY};
      }
    }

    &__client {
      color: ${COLOR_TEXT_GRAY};
      text-align: right;
      font-weight: 400;
      margin-top: 20px;
    }
  }
`;

// Mobile Content
const MobileDisplayImageWrapper = styled.div`
  position: relative;
  @media ${screen.large} {
    display: none;
  }

  .mobile-content {
    position: absolute;
    /* bottom: 18%; */
    bottom: 3%;
    padding: 0 35px;
    max-width: 430px;

    h2 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 20px;
      @media ${screen.medium} {
        font-size: 1.8rem;
      }
    }

    a {
      background: ${COLOR_GRAY};
      display: block;
      max-width: 600px;
      font-size: 1.3rem;
      font-weight: 100;
      margin: 0 auto;
      text-align: center;
      box-sizing: border-box;
      padding: 10px 13px;
      border-radius: 5px;
      margin-bottom: 10px;
      transition: all 0.28s ease-out;
      &:hover {
        background-color: #69ad3f !important;
      }
      @media ${screen.medium} {
        font-size: 1.6rem;
        padding: 10px 30px;
        margin-bottom: 60px;
      }
    }
  }
`;

// START CUSTOM STYLE ARROW BUTTONS FOR CAROUSEL =================
const NextBtn = styled.div`
  color: ${OVERLAY_GRAY};
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 8;
  color: ${OVERLAY_GRAY};
  top: ${(props) => (props.testimonial ? `37%` : `50%`)};
  right: 50px;
  transform: translateY(-50%);
  img {
    width: 32px;
    height: 50px;
    opacity: 0.3;
  }
`;

const PrevBtn = styled.div`
  color: ${OVERLAY_GRAY};
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 8;
  color: ${OVERLAY_GRAY};
  top: ${(props) => (props.testimonial ? `37%` : `50%`)};
  left: 50px;
  transform: translateY(-50%);
  img {
    width: 32px;
    height: 50px;
    opacity: 0.3;
  }
`;

const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <PrevBtn testimonial={props.testimonial} onClick={onClick}>
      <img src={leftArrow} alt="left" />
    </PrevBtn>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;

  return (
    <NextBtn testimonial={props.testimonial} onClick={onClick}>
      <img src={rightArrow} alt="right" />
    </NextBtn>
  );
};
// END CUSTOM STYLE ARROW BUTTONS FOR CAROUSEL =================
class HomePage extends Component {
  state = {
    showBannerCarouselControls: false,
    showTestimonialCarouselControls: false,
    hideShowTextScenarios:
      this.props.data.homeData.acfHomePage.scenarios.reduce((a, b) => {
        a[b.title] = false; // set to false to hide texts for each scenarios
        return a;
      }, {}),
  };

  render() {
    const {
      showBannerCarouselControls,
      showTestimonialCarouselControls,
      hideShowTextScenarios,
    } = this.state;
    const carouselBannerSettings = {
      // settings for react carousel
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: showBannerCarouselControls ? <NextArrow /> : null,
      prevArrow: showBannerCarouselControls ? <PrevArrow /> : null,
    };
    const phoneNumber = '1300 739 913';

    const carouselTestimonialSettings = {
      // settings for react carousel
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: showTestimonialCarouselControls ? (
        <NextArrow testimonial />
      ) : null,
      prevArrow: showTestimonialCarouselControls ? (
        <PrevArrow testimonial />
      ) : null,
    };

    // WP DATA START =================
    const sliders = this.props.data.homeData.acfHomePage.slider;
    const scenariosHeading =
      this.props.data.homeData.acfHomePage.scenariosHeading.heading;
    const scenariosSubheading =
      this.props.data.homeData.acfHomePage.scenariosHeading.subheading;
    const scenarios = this.props.data.homeData.acfHomePage.scenarios;
    const testimonialsHeading =
      this.props.data.homeData.acfHomePage.testimonialsHeading;
    const testimonials = this.props.data.homeData.acfHomePage.testimonials;
    const featuredContent = this.props.data.featuredContent.edges;
    const mobileDisplayImage =
      this.props.data.homeData.acfHomePage.smallScreenDisplayImage.localFile
        .childImageSharp.gatsbyImageData;

    // WP DATA END =====================

    const renderBannerSliders = sliders.map((slider) => {
      // Use the first line of the slider content for the alt tag

      return (
        <div className="banner-slider" key={slider.image.id}>
          <div className="banner-slider__image">
            {slider.image.localFile && (
              <GatsbyImage
                image={slider.image.localFile.childImageSharp.gatsbyImageData}
                alt={slider.image.altText || 'Source Separation Systems'}
              />
            )}

            <div
              className={
                slider.textAlign === 'Left'
                  ? 'banner-slider__content-left'
                  : 'banner-slider__content-right'
              }
              id={slider.textAlign}
              dangerouslySetInnerHTML={{
                __html: slider.content
                  .replace(/<h2>/g, '<div class="desc">')
                  .replace(/<\/h2>/g, '</div>'),
              }}
            />
          </div>
        </div>
      );
    });

    const renderScenarios = scenarios.map((scenario, index) => {
      return (
        <div
          key={scenario.title}
          className="scenarios"
          onMouseEnter={() => {
            hideShowTextScenarios[scenario.title] = true;
            this.setState({ hideShowTextScenarios });
          }}
          onMouseLeave={() => {
            hideShowTextScenarios[scenario.title] = false;
            this.setState({ hideShowTextScenarios });
          }}
        >
          <Link to={scenario.link.url}>
            <GatsbyImage
              className="scenarios__image"
              alt={scenario.image.altText || 'Source Separation Systems'}
              image={scenario.image.localFile.childImageSharp.gatsbyImageData}
            />

            <div
              className="scenarios__text-container"
              style={{
                opacity: hideShowTextScenarios[scenario.title] ? 1 : 0,
              }}
            >
              <div className="scenarios__text-container__subcontainer">
                <h2 className="scenarios__title">{scenario.title}</h2>
                <p className="scenarios__content">{scenario.content}</p>

                <p className="scenarios__link">
                  {scenario.link.title} <FaArrowAltCircleRight />
                </p>
              </div>
            </div>
          </Link>

          <a
            className="scenarios__mobile-navigate-link"
            href={scenario.link.url}
          >
            {/* This will only show on small screen */}
            <h2>{scenario.title}</h2>
            <FaArrowAltCircleRight />
          </a>
        </div>
      );
    });

    const renderTestimonialSliders = testimonials.map((testimonial) => {
      return (
        <div className="testimonial-slider" key={testimonial.client}>
          <div className="testimonial-slider__image">
            <img
              aria-hidden="true"
              alt="Icon"
              src={testimonial.image.localFile.publicURL}
            />
          </div>
          <div className="testimonial-slider__texts-container">
            <div
              className="testimonial-slider__content"
              dangerouslySetInnerHTML={{ __html: testimonial.content }}
            />
            <p className="testimonial-slider__client">{testimonial.client}</p>
          </div>
        </div>
      );
    });

    const { acfHomePage } = this.props.data.homeData;
    const pageTitle =
      (acfHomePage.seo && acfHomePage.seo.pageTitle) ||
      'Source Separation Systems';
    const pageDescription = acfHomePage.seo && acfHomePage.seo.description;

    return (
      <Layout>
        <SEO
          title={pageTitle}
          description={pageDescription}
          image={withPrefix('/og_images/home.jpeg')}
        />
        <JSONLD dangerouslyExposeHtml>
          <GenericCollection type="review">
            {testimonials.map((t) => (
              <Review
                name={t.client}
                key={t}
                reviewBody={removeHTMLTags(t.content)}
              />
            ))}
          </GenericCollection>
        </JSONLD>

        <Wrapper>
          <div className="home">
            <MobileDisplayImageWrapper>
              <div className="display-image">
                <GatsbyImage image={mobileDisplayImage} alt="coffee bin" />
              </div>

              <div className="mobile-content">
                <h2>
                  Connecting
                  <br />
                  you to a<br />
                  sustainable world
                  <br />
                  through best practice
                  <br />
                  recycling products.
                </h2>
                <div className="call-us-button">
                  <a href={`tel:${phoneNumber}`}>
                    Call us to discuss your goals
                  </a>
                </div>
              </div>
            </MobileDisplayImageWrapper>
            <CarouselWrapper
              onMouseEnter={() =>
                this.setState({ showBannerCarouselControls: true })
              }
              onMouseLeave={() =>
                this.setState({ showBannerCarouselControls: false })
              }
              className="carousel"
            >
              <Slider {...carouselBannerSettings}>{renderBannerSliders}</Slider>
            </CarouselWrapper>
            <ScenariosWrapper>
              <h2 className="heading">{scenariosHeading}</h2>
              <p className="subheading">{scenariosSubheading}</p>
              <div className="scenarios-list">
                {renderScenarios}
                {/* START SERVICES TILE ON MOBILE */}
                <div className="scenarios scenarios--services">
                  <a href="/services">
                    <StaticImage
                      src="../images/source-separation-systems-categories-services.jpg"
                      alt="Services"
                    />
                    <div className="scenarios__text-container">
                      <div className="scenarios__text-container__subcontainer">
                        <h2 className="scenarios__title">Services</h2>
                        <p className="scenarios__content">Services</p>

                        <p className="scenarios__link">
                          Services <FaArrowAltCircleRight />
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    className="scenarios__mobile-navigate-link"
                    href="/services"
                  >
                    <h2>Services</h2>
                    <FaArrowAltCircleRight />
                  </a>
                </div>
                {/* END SERVICES TILE ON MOBILE */}
              </div>
              <div />
            </ScenariosWrapper>
            <TestimonialWrapper
              onMouseEnter={() =>
                this.setState({ showTestimonialCarouselControls: true })
              }
              onMouseLeave={() =>
                this.setState({ showTestimonialCarouselControls: false })
              }
            >
              <h2 className="heading">{testimonialsHeading}</h2>
              <Slider {...carouselTestimonialSettings}>
                {renderTestimonialSliders}
              </Slider>
            </TestimonialWrapper>
          </div>
        </Wrapper>
        <FeaturedSection content={featuredContent} />
      </Layout>
    );
  }
}
export default HomePage;
export const query = graphql`
  {
    homeData: wpPage(id: { eq: "cG9zdDo5" }) {
      id
      acfHomePage {
        seo {
          description
          pageTitle
        }
        smallScreenDisplayImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        slider {
          textAlign
          content
          image {
            altText
            id
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        scenariosHeading {
          heading
          subheading
        }
        scenarios {
          title
          content
          link {
            url
            target
            title
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        testimonialsHeading
        testimonials {
          content
          client
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    featuredContent: allWpPost(
      limit: 3
      sort: { fields: modified, order: DESC }
      filter: { tags: { nodes: { elemMatch: { name: { eq: "Home Page" } } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          acfBlog {
            content
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 670, height: 490)
                }
              }
            }
          }
        }
      }
    }
  }
`;
